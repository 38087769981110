import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { B2BUnit, WindowRef } from '@spartacus/core';
import { getUrlOfEndpoint } from '@util/functions/strings';
import { Observable, of } from 'rxjs';
import { KurzMyAccountService } from '../../services/kurz-my-account.service';
import { filter, map, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { ActiveCartService, CartActions } from '@spartacus/cart/base/core';

@Injectable({
  providedIn: 'root'
})
export class KurzB2bUnitChooserService {

  currentB2BUnit: string;

  constructor(
    private httpClient: HttpClient,
    private kurzMyAccountService: KurzMyAccountService,
    private windowRef: WindowRef,
    private activeCartService: ActiveCartService,
    private readonly store: Store
  ) {

    this.getCurrentB2BUnit$();
  }

  getAvailableB2BUnits(): Observable<B2BUnit[]> {
    const endpoint = '/customer-manager/B2BUnits/';
    const url = getUrlOfEndpoint(endpoint, { fields: 'BASIC' });

    return this.httpClient.get<B2BUnit[]>(url);
  }

  changeActiveB2BUnit(b2bUnit: string) {
    this.currentB2BUnit = b2bUnit;
    this.windowRef.nativeWindow.localStorage.setItem('b2bUnit', this.currentB2BUnit);

    this.activeCartService.getActiveCartId().pipe(filter(_ => !!_)).subscribe(cartId => this.store.dispatch(new CartActions.RemoveCart({ cartId })));

    const origin = this.windowRef.nativeWindow.location.origin;
    // 0 -> baseId, 1 -> language, -> currency, i.e. "kurz-de/de/EUR"
    const path = this.windowRef.nativeWindow.location.pathname.split('/').filter(Boolean).slice(0, 3).join('/');
    const homepageUrl = origin + '/' + path + '/';

    // refreshes the complete browser page in order to force spartacus to clean chaches for things like product details, which change if the B2B-Unit changed
    this.windowRef.nativeWindow.location.href = homepageUrl;
  }

  getCurrentB2BUnit$(): Observable<string> {
    this.currentB2BUnit = this.windowRef.nativeWindow.localStorage.getItem('b2bUnit');

    if (!this.currentB2BUnit) {
      return this.kurzMyAccountService.getUser().pipe(
        filter(user => !!user),
        tap(user => {
          this.currentB2BUnit = user.orgUnit?.uid;
          this.windowRef.nativeWindow.localStorage.setItem('b2bUnit', this.currentB2BUnit);
        }),
        map(user => user.orgUnit?.uid));
    }

    return of(this.currentB2BUnit);
  }

  isCurrentUserB2BManager$(): Observable<boolean> {
    return this.kurzMyAccountService.getUser().pipe(
      filter(user => !!user),
      map(user => user.roles.includes('b2bmanagergroup'))
    );
  }
}

export class KurzB2bUnitChooserTestingService {
  currentB2BUnit = 'mockB2BUnitUid';

  getAvailableB2BUnits(): Observable<B2BUnit[]> {
    return of([{}]);
  }

  changeActiveB2BUnit(b2bUnit: string) { }

  getCurrentB2BUnit$(): Observable<string> {
    return of('');
  }

  isCurrentUserB2BManager$(): Observable<boolean> {
    return of(true);
  }

}
