import {Component} from '@angular/core';
import {KurzB2bUnitChooserService} from './kurz-b2b-unit-chooser.service';
import {AuthService, B2BUnit} from '@spartacus/core';
import {BehaviorSubject, combineLatest, Observable, Subscription} from 'rxjs';
import {DropdownDataSource} from '@util/components/dropdown/dropdown-data-source.class';
import {filter, skip, tap} from 'rxjs/operators';

@Component({
  selector: 'app-kurz-b2b-unit-chooser',
  templateUrl: './kurz-b2b-unit-chooser.component.html',
  styleUrls: ['./kurz-b2b-unit-chooser.component.scss']
})
export class KurzB2bUnitChooserComponent {

  b2bUnitDataSource: DropdownDataSource;
  b2bUnitDataSourceObservable$: BehaviorSubject<DropdownDataSource>;
  b2bUnitSubscription: Subscription;
  isB2BManager: boolean = false;

  constructor(
    private kurzB2BUnitChooserService: KurzB2bUnitChooserService,
    private authService: AuthService
  ) {

    this.authService.isUserLoggedIn().subscribe(_ => {
      if (!_) {
        this.b2bUnitSubscription?.unsubscribe();
        this.b2bUnitDataSource = null;
        this.b2bUnitDataSourceObservable$ = null;
        this.kurzB2BUnitChooserService.currentB2BUnit = null;
        window.localStorage.setItem('b2bUnit', '');
      } else {
        this.initB2BUnitChooserContent();
      }
    });
  }

  getAvailableB2BUnits(): Observable<B2BUnit[]> {
    return this.kurzB2BUnitChooserService.getAvailableB2BUnits();
  }

  private initB2BUnitChooserContent() {
    this.b2bUnitDataSource = new DropdownDataSource({ multi: false }, []);
    this.b2bUnitDataSourceObservable$ = new BehaviorSubject<DropdownDataSource>(null);

    // when switching user without manual refresh, getAvailableB2BUnits() will still return the old values, despite the request failing
    this.kurzB2BUnitChooserService.isCurrentUserB2BManager$().pipe(
      tap(isB2BManager => this.isB2BManager = isB2BManager),
      filter(_ => !!_)
    ).subscribe(_ => {

      combineLatest([
        this.getAvailableB2BUnits(),
        this.kurzB2BUnitChooserService.getCurrentB2BUnit$()
      ]).subscribe(([b2bUnits, currentB2BUnit]) => {
        let b2bUnitDataSourceItems = b2bUnits.map((b2bUnit) => ({ value: b2bUnit.uid, label: b2bUnit.name, selected: b2bUnit.uid === currentB2BUnit }));
        this.b2bUnitDataSource.setItems(b2bUnitDataSourceItems.sort((a, b) => a.value.localeCompare(b.value)));
        this.b2bUnitDataSourceObservable$.next(this.b2bUnitDataSource);
      });

      this.b2bUnitSubscription = this.b2bUnitDataSource.selectedItems$
        .pipe(skip(2))
        .subscribe(b2bUnit => this.kurzB2BUnitChooserService.changeActiveB2BUnit(b2bUnit[0].value));
    });
  }


}
